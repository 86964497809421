body {
    margin: 0;
    font-family: 'Figtree', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(247, 247, 247);
    letter-spacing: 1px;
  }
  
  :root {
    --content-container-width: 1070px;
  
    --primary-color-dark: #28242c;
    --primary-color-light: rgb(91, 202, 250);
    --secondary-color-dark: #2c947f;
    --secondary-color-light: #44CBB1;
    --tertiary-color-dark: #617075ff;
    --primary-overlay: #1abc9c75;  
    --slideshow-container-height: 600px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  h1 {
    font-weight: 800 !important;
  }

  .logo {
    width: 100%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    /* padding-left: 8px; */
    /* padding-right: 8px; */
  }

  /* .hero-img-container {
    padding-top: 40px;
  } */

  .hero-text {
    color: white;
    width: 558px;
    margin-left: 30px;
  }

  .hero-text h1 {
    font-size: 60px;
    font-weight:100;
    padding-top: 300px;
    /* padding-left: 30px; */
  }

  .hero-image {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    /* background-color: rgb(179, 19, 19); */
    /* Set a specific height */
    max-width: 1320px;
    width: 100%;
    margin-top: 50px;
    margin: auto;
    height: 600px;
    /* width: 100%; */
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    animation: fade-in 1.8s;
  }

  .home-hero-image {
    background-image: url("./images/hero-image.jpg");
  }

  .notes-hero-image {
    background-image: url("./images/runner-1.jpg");
  }

.justify-content-end {
    justify-content: flex-end!important;
}

.header-container {
  display: block!important;
}

.navbar-expand .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
    /* flex-wrap: ; */
    
}

.navbar {
  background-color: var(--secondary-color-light);
  flex-wrap: wrap!important;
}

.nav-link {
  /* font-size:; */
  /* color: white!important; */
  /* font-weight: bold; */
}

.navbar-brand {
  margin: 0px;
  width: 100%;
}

.CardGroup {
    /* padding-top: 40px; */
    max-width: 1320px;
    width: 100%;
    column-gap: 30px;
}

.footer-container {
  background-color: var(--primary-color-dark);
  width: 100%;
  /* margin-top: calc(100vh - 60000px); */
  /* position: fix */
}

.footer {
  background-color: var(--primary-color-dark);
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  /* font-weight: bold; */
  font-size: 14px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}

.footer .footer-left {
  width: 33%;
}

.footer .about {
  line-height: 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.footer .about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer .icons {
  margin-top: 10px;
}

.footer .icons a {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
}

.footer-icon {
  width: 2rem;
  height: 2rem;
  color: white;
  transition: all 250ms;
  margin-right: 15px;
}

.footer-icon:hover {
  color: #1abc9c;
  transform: translateY(-2px);
}

.footer .footer-center {
  width: 30%;
}

.footer .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: #0099ff;
  text-decoration: none; 
}

.footer .footer-right {
  width: 35%;
}

.footer h2 {
  color: #ffffff;
  font-size: 28px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: #0099ff;
}

.footer .menu {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: #0099ff;
}

.footer .name {
  color: #0099ff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.contact-info {
  padding-bottom: 20px;
}

.padding-top {
  padding-top: 50px;
}

hr {
  border: 1px solid black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 90%;
}

button {
  background-color: var(--secondary-color-light)!important;
  border-color: var(--secondary-color-light)!important;
}

.button {
  background-color: var(--secondary-color-light)!important;
  border-color: var(--secondary-color-light)!important;
}

button:hover {
  background-color: var(--secondary-color-dark)!important;
  border-color: var(--secondary-color-dark)!important;
  transition: 0.2s;
}

.button:hover {
  background-color: var(--secondary-color-dark)!important;
  border-color: var(--secondary-color-dark)!important;
  transition: 0.2s;
}

.content {
  min-height: calc(100vh - 400px);
}

.textarea {
  min-height: 150px;
}

.map-container{
  overflow: hidden; 
  position: relative;
}

.map-div {
  position:relative;
  width:100%;
  padding-top:56.25%;
  overflow:visible;
}

.map-iframe {
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
}

.text-border{
  border: 1px solid black;
  background-color: black;
  color: var(--secondary-color-light)!important;
  width: fit-content;
}

.text-border:hover {
  background-color: var(--secondary-color-light)!important;
  color: black!important;
  transition: 0.2s;
}

.cta-button{
  border: 1px solid var(--secondary-color-light)!important;
  background-color: var(--secondary-color-light)!important;
  padding: 6px;
  width: fit-content;
  color: white!important;
}

.scrollToTop {
  /* margin: auto; */
  /* position: fixed;  */
   /* width: 100%; */
   left: 50%;
   bottom: 40px;
   /* height: 20px; */
}

.goTopHidden{
  display: none;
}

.goTop{
  display: inline-block;
}

/* .FaArrowUp{
  width: 10px;
} */

.goTop__text{
  position: fixed;
  display: flex;
  justify-content: center;
  padding:6px;
   font-size: 30px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  bottom: 5%;
  left: 90%;
  cursor: pointer;
  text-align: center;
  align-items: center;
}

.goTop__text:hover{
  transform: scale;
}

@media (max-width: 1000px) {
  .footer {
    font-size: 14px;
  }
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footer .footer-center i {
    margin-left: 0;
  }
}

.bold-text {
  font-weight: bold;
}

.padding-spacing {
  padding-top: 40px;
}

@media (max-width: 770px) {
  .hero-text {
    max-width: 350px;
    margin: 10px;
    width: 100%;
  }

  .hero-text h1 {
    font-size: 42px;
    padding-top: 350px;
  }
}

@media (max-width: 500px) {
  .padding-spacing{
    padding-top: 15px;
  }

  .hero-image{
    height: 450px;
  }

  .hero-text h1 {
    padding-top: 280px;
    font-size: 32px;
  }

  .hero-text {
    max-width: 300px;
    margin: 10px;
    width: 100%;
  }

  .goTop__text{
    position: fixed;
    display: flex;
    justify-content: center;
    padding:6px;
     font-size: 30px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    bottom: 5%;
    left: 85%;
    cursor: pointer;
    text-align: center;
    align-items: center;
  }
}